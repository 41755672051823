import style from "./Index.module.scss";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { WarningIcon } from "../Icon";

interface Props {
  show: boolean;
  message: string;
}

export default function ErrorToast({ show, message }: Props) {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={3000}
      classNames="fade"
      unmountOnExit
    >
      <div className={style.Container} ref={nodeRef}>
        <WarningIcon />
        <div className={style.Message}>{message}</div>
      </div>
    </CSSTransition>
  );
}
