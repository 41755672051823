import style from "./Index.module.scss";
import { ReactNodeProps } from "@logicflow/react-node-registry";
import ItemNode from "../ItemNode";
import {
  SignalIcon,
  FrequencyIcon,
  LevelIcon,
  ModulationIcon,
  ExecutionModeIcon,
  DelayIcon,
} from "../../Icon";
import Form from "../../form/Index";
import SelectorDataBox from "../../data-box/selector/Index";
import InputFieldDataBox from "../../data-box/input/Index";
import { useState, useEffect } from "react";

export default function SignalTestNode({ node, graph }: ReactNodeProps) {
  let properties = node.getProperties();
  const [signalType, setSignalType] = useState<string>(
    properties.signalType || "AM"
  );
  const [frequency, setFrequency] = useState<number>(
    properties.frequency || 208064000
  );
  const [level, setLevel] = useState<number>(properties.level || -40);
  const [modulation, setModulation] = useState<number>(
    properties.modulation || 0
  );
  const [executionMode, setExecutionMode] = useState<string>(
    properties.executionMode || "Automatic"
  );
  const [delay, setDelay] = useState<number>(properties.delay || 5000);

  useEffect(() => {
    node.setProperty("signalType", signalType);
  }, [signalType]);

  useEffect(() => {
    node.setProperty("frequency", frequency);
  }, [frequency]);

  useEffect(() => {
    node.setProperty("level", level);
  }, [level]);

  useEffect(() => {
    node.setProperty("modulation", modulation);
  }, [modulation]);

  useEffect(() => {
    node.setProperty("executionMode", executionMode);
  }, [executionMode]);

  useEffect(() => {
    node.setProperty("delay", delay);
  }, [delay]);

  return (
    <ItemNode node={node} graph={graph}>
      <div className={style.Detail}>
        <Form title="Signal Parameters">
          <SelectorDataBox
            Icon={SignalIcon}
            label="Signal Type"
            value={signalType}
            options={["AM", "FM", "DAB"]}
            onChange={setSignalType}
          />
          <InputFieldDataBox
            Icon={FrequencyIcon}
            label="Frequency"
            value={frequency}
            onChange={setFrequency}
            formatter={frequencyFormatter}
            placeholder="Frequency"
          />
          <InputFieldDataBox
            Icon={LevelIcon}
            label="Level"
            value={level}
            onChange={setLevel}
            placeholder="Level"
          />
          <InputFieldDataBox
            Icon={ModulationIcon}
            label="Modulation"
            value={modulation}
            onChange={setModulation}
            placeholder="Modulation"
          />
        </Form>

        <Form title="Execution">
          <SelectorDataBox
            Icon={ExecutionModeIcon}
            label="Mode"
            value={executionMode}
            options={["Automatic", "Manual"]}
            onChange={setExecutionMode}
          />
          {executionMode === "Automatic" && (
            <InputFieldDataBox
              Icon={DelayIcon}
              label="Delay"
              value={delay}
              onChange={setDelay}
              formatter={delayFormatter}
              placeholder="Delay"
            />
          )}
        </Form>
      </div>
    </ItemNode>
  );
}

const frequencyFormatter = (value: number): string => {
  if (isNaN(value)) return "Invalid input";
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(2)} GHz`;
  } else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)} MHz`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(2)} kHz`;
  } else {
    return `${value.toFixed(2)} Hz`;
  }
};

const delayFormatter = (value: number): string => {
  if (isNaN(value)) return "Invalid input";
  return `${value} ms`;
};
