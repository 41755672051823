import style from "./Index.module.scss";

interface Props {
  title: string;
  children?: React.ReactNode;
}

export default function Form({ title, children }: Props) {
  return (
    <div className={style.Container}>
      <div className={style.Title}>{title}</div>
      <div className={style.Cards}>{children}</div>
    </div>
  );
}
