import LogicFlow from "@logicflow/core";
import { BaseNodeModel, Model } from "@logicflow/core/lib/model";
import { ReactNodeProps } from "@logicflow/react-node-registry";
import FlowNode, { FlowNodeModel } from "./node/Index";
import { StartIcon } from "./Icon";
import CardHeader from "./node/header/Index";

export class StartNodeModel extends FlowNodeModel {
  initNodeData(data: LogicFlow.NodeConfig): void {
    super.initNodeData(data);
    this.targetRules = [
      {
        message: "The start node cannot accept any input.",
        validate: () => {
          return false;
        },
      },
    ];
    this.sourceRules = [
      {
        message: "Output cannot have outgoing connections.",
        validate: (
          _sourceNode?: BaseNodeModel,
          _targetNode?: BaseNodeModel,
          _sourceAnchor?: Model.AnchorConfig,
          targetAnchor?: Model.AnchorConfig
        ) => {
          return targetAnchor?.type === "incomming";
        },
      },
      {
        message: "Start node can only accept one output.",
        validate: (
          sourceNode?: BaseNodeModel,
          _targetNode?: BaseNodeModel,
          _sourceAnchor?: Model.AnchorConfig,
          _targetAnchor?: Model.AnchorConfig
        ) => {
          if (!sourceNode) {
            return true;
          }

          const edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
          return edges.length === 0;
        },
      },
    ];
  }

  getDefaultAnchor(): Model.AnchorConfig[] {
    const { id, x, y, width } = this;
    const anchors = [];
    anchors.push({
      x: x + width / 2,
      y,
      id: `${id}_outgoing`,
      type: "outgoing",
    });
    return anchors;
  }
}

export default function StartNode({ node, graph }: ReactNodeProps) {
  return (
    <FlowNode node={node} graph={graph} type="start-node">
      <CardHeader Icon={StartIcon} title="Start"></CardHeader>
    </FlowNode>
  );
}
