import style from "./Index.module.scss";
import { useEffect, useState, useRef } from "react";
import DataBox from "../Index";

interface Props {
  label: string;
  Icon: React.ElementType;
  value: string;
  options: string[];
  onChange: (newValue: string) => void;
}

export default function SelectorDataBox({
  label,
  Icon,
  value,
  options,
  onChange,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={style.Container}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <DataBox Icon={Icon} label={label} value={value} />
      {isOpen && (
        <div className={style.Editor}>
          <div className={style.Header}>
            <Icon />
            <div>{label}</div>
          </div>
          <div className={style.Options}>
            {options.map((option, index) => (
              <div
                className={style.Option}
                key={index}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  handleSelect(option);
                }}
                data-active={selectedOption === option}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
