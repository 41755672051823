import LogicFlow from "@logicflow/core";
import { BaseNodeModel, Model } from "@logicflow/core/lib/model";
import { FlowNodeModel } from "../node/Index";

export default class ItemNodeModel extends FlowNodeModel {
  initNodeData(data: LogicFlow.NodeConfig): void {
    super.initNodeData(data);
    this.sourceRules = [
      {
        message: "Output cannot have outgoing connections.",
        validate: (
          _sourceNode?: BaseNodeModel,
          _targetNode?: BaseNodeModel,
          _sourceAnchor?: Model.AnchorConfig,
          targetAnchor?: Model.AnchorConfig
        ) => {
          return targetAnchor?.type === "incomming";
        },
      },
      {
        message: "This node can only accept one output.",
        validate: (
          sourceNode?: BaseNodeModel,
          _targetNode?: BaseNodeModel,
          _sourceAnchor?: Model.AnchorConfig,
          _targetAnchor?: Model.AnchorConfig
        ) => {
          if (!sourceNode) {
            return true;
          }

          const edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
          return edges.length === 0;
        },
      },
    ];
    this.targetRules = [
      {
        message: "Input cannot have incomming connections.",
        validate: (
          _sourceNode?: BaseNodeModel,
          _targetNode?: BaseNodeModel,
          sourceAnchor?: Model.AnchorConfig,
          _targetAnchor?: Model.AnchorConfig
        ) => {
          return sourceAnchor?.type === "outgoing";
        },
      },
      {
        message: "This node can only accept one input.",
        validate: (
          _sourceNode?: BaseNodeModel,
          targetNode?: BaseNodeModel,
          _sourceAnchor?: Model.AnchorConfig,
          _targetAnchor?: Model.AnchorConfig
        ) => {
          if (!targetNode) {
            return true;
          }

          const edges = this.graphModel.getNodeIncomingEdge(targetNode.id);
          return edges.length === 0;
        },
      },
    ];
  }

  getDefaultAnchor(): Model.AnchorConfig[] {
    const { id, x, y, height } = this;
    const anchors = [];
    anchors.push({
      x,
      y: y + height / 2,
      id: `${id}_outgoing`,
      type: "outgoing",
    });
    anchors.push({
      x,
      y: y - height / 2,
      id: `${id}_incomming`,
      type: "incomming",
    });
    return anchors;
  }
}
