import style from "./Index.module.scss";
import React from "react";

type Props = {
  Icon: React.ElementType;
  title: string;
  onChange?: (newValue: string) => void;
};

export default function CardHeader({ Icon, title, onChange }: Props) {
  return (
    <div className={style.Container}>
      <Icon></Icon>
      {onChange ? (
        <input
          type="text"
          value={title}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Item Name"
        />
      ) : (
        <div>{title}</div>
      )}
    </div>
  );
}
