import style from "./Index.module.scss";
import { useEffect, useRef, useState } from "react";
import { BaseNodeModel } from "@logicflow/core/lib/model";
import { ReactNodeProps } from "@logicflow/react-node-registry";

interface Props extends ReactNodeProps {
  type: string;
  children?: React.ReactNode;
}

export class FlowNodeModel extends BaseNodeModel {
  setAttributes(): void {
    let properties = super.getData().properties;
    if (properties) {
      if (properties.width) {
        this.width = properties.width;
      }

      if (properties.height) {
        this.height = properties.height;
      }
    }
  }
}

export default function FlowNode({ node, graph, type, children }: Props) {
  const selfRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((_) => {
      const rect = selfRef.current?.getBoundingClientRect();
      setSize({ width: rect?.width || 0, height: rect?.height || 0 });
    });

    if (selfRef.current) {
      resizeObserver.observe(selfRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const scaleX = graph.transformModel.SCALE_X;
    const scaleY = graph.transformModel.SCALE_Y;
    node.width = size.width / scaleX;
    node.height = size.height / scaleY;

    let properties = node.getData().properties;
    if (properties) {
      properties.width = node.width;
      properties.height = node.height;
    }
  }, [size]);

  return (
    <div
      className={style.Container}
      onClick={() => {
        //ensure compatibility with Chromium
        graph.clearSelectElements();
        node.setSelected(true);
        window.dispatchEvent(
          new CustomEvent("element:click", {
            detail: {
              data: {
                type,
              },
            },
          })
        );
      }}
    >
      <div className={style.CardContainer} ref={selfRef}>
        {children}
      </div>
    </div>
  );
}
