import LogicFlow from "@logicflow/core";
import LocalStorageService from "./localStorageService";

interface IFlowChartPersistenceService {
  setOnAutoSaveListener(callback: () => void): void;
  setData(data: any): void;
}

export const STORAGE_KEY = "flowChartData";

export default class FlowChartPersistenceService
  implements IFlowChartPersistenceService
{
  private logicFlow: LogicFlow;
  private localStorageService: LocalStorageService;
  private onAutoSavedListener: (() => void) | null = null;

  constructor(logicFlow: LogicFlow) {
    this.logicFlow = logicFlow;
    this.localStorageService = new LocalStorageService();
    this.loadData();

    logicFlow.on("history:change", () => {
      this.saveData();
      if (this.onAutoSavedListener) {
        this.onAutoSavedListener();
      }
    });
  }

  setData(data: any): void {
    const graphData: LogicFlow.GraphData = data;
    if (!graphData.nodes || !graphData.edges) {
      return;
    }

    this.logicFlow.render(data);
    this.logicFlow.translateCenter();
  }

  setOnAutoSaveListener(listener: () => void): void {
    this.onAutoSavedListener = listener;
  }

  private saveData() {
    const graphData = this.logicFlow.getGraphData();
    this.localStorageService.setItem<LogicFlow.GraphData | unknown>(
      STORAGE_KEY,
      graphData
    );
  }

  private loadData() {
    const savedData = this.localStorageService.getItem<
      LogicFlow.GraphData | unknown
    >(STORAGE_KEY);
    if (savedData) {
      this.logicFlow.render(savedData);
      this.logicFlow.translateCenter();
      return;
    }

    this.renderDefaultData();
  }

  private renderDefaultData() {
    this.logicFlow.render({
      nodes: [
        {
          type: "start-node",
          x: 100,
          y: 100,
        },
        {
          type: "stop-node",
          x: 800,
          y: 100,
        },
      ],
    });

    this.logicFlow.translateCenter();
  }
}
