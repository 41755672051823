import style from "./Index.module.scss";

interface Props {
  label: string;
  Icon: React.ElementType;
  value: string;
}

export default function DataBox({ label, Icon, value }: Props) {
  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <Icon />
        <div>{label}</div>
      </div>
      <div className={style.Value}>{value}</div>
    </div>
  );
}
